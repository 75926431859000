import React from 'react';
import Layout from '../../components/Layout';
import { withIntl } from '../../i18n';
import FormPage from '../../templates/FormPage';
const host = process.env.GATSBY_HOST;

const support = {
  title: 'Corezoid as a Back-end',
  subTitle: 'Отправьте запрос на получение доступа и документации к использованию Corezoid для управления вашим веб или мобильным приложением.',
  form: {
    title: 'Contact us',
    type: 'caab',
    fields: [
      {
        type: 'firstName',
        label: 'Имя',
        value: ''
      },
      {
        type: 'lastName',
        label: 'Фамилия',
        value: ''
      },
      {
        type: 'email',
        label: 'Email',
        value: ''
      },
      {
        type: 'type',
        label: 'Тип приложения',
        value: '',
        list: [
          {
            value: 'Веб'
          },
          {
            value: 'Мобильное'
          },
          {
            value: 'Веб и мобильное'
          }
        ]
      },
      {
        type: 'message',
        label: 'Ваше сообщение',
        value: ''
      },
    ],
    button: {
      text: 'Отправить',
      link: `${host}/api/1/json/public/513644/6d85995d468d2cd7d26930393473dff049842412`,
    }
  }
};

class FormSupport extends React.PureComponent {
  render() {
    return (
      <Layout>
        <FormPage data={support} />
      </Layout>
    );
  }
}

export default withIntl(FormSupport);

